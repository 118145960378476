import React, { useState, useContext } from "react";
import { StoreContext } from "../../../store";

import Button from "react-bootstrap/Button";
import "../../../../src/styles.less";
import ModalWrapper from "../modal/Modal";
import { SendMessage } from "../../../actions";

const ContactUsModal = (props) => {
  const { dispatch } = useContext(StoreContext);

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [MessageBody, setMessageBody] = useState("");
  const [missingContactInfo, setMissingContactInfo] = useState(false);
  const [missingMessage, setMissingMessage] = useState(false);

  let handleSend = () => {

    
    var Message = { Name: "", Email: "", Phone: "", MessageBody: "" };
    Message.Name = Name;
    Message.Email = Email;
    Message.Phone = Phone;
    Message.MessageBody = MessageBody;

    Message.Recipient = (props.contactInfo !=null)? props.contactInfo.Email:'';

    if(Message.Phone =="" && Message.Email=="")
    {
     setMissingContactInfo(true);
     setMissingMessage(false);
    }
    else if(Message.MessageBody =="")
    {
      setMissingContactInfo(false);
      setMissingMessage(true);
    }
    else
    {
      
      SendMessage(dispatch, Message);
      props.closeModal();
    }
    
  };

  let Title = "Contact Us"; 
  if(props.contactInfo != null)
  {
     Title = "Contact " + props.contactInfo?.Name;
  }
  return (
    <ModalWrapper
      title={Title}
      show={props.show}
      closeModal={props.closeModal}
      handleOnClick={() => handleSend()}
    >
      <div className="container contact-us-container">

        {(missingContactInfo?<div style={{color:'red', fontStyle:'italic'}}>Please fill out your email or your contact number.</div>:'')}
        {(missingMessage?<div style={{color:'red', fontStyle:'italic'}}>Please fill out the message box .</div>:'')}

        <div className="row">
          <div className="col-sm">
            <form>
              <ul className="flex-outer">
                <li>
                  <label for="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Your Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </li>
                <li>
                  <label for="name">Email</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Your Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </li>
                <li>
                  <label for="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    placeholder="Your Phone"
                    value={Phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </li>

                <li>
                  <label for="Message">Your Message</label>

                  <textarea
                    className="contact-ta"
                    id="message"
                    value={MessageBody}
                    onChange={(e) => setMessageBody(e.target.value)}
                  />
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
export default ContactUsModal;
