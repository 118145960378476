/* eslint-disable */

import React from "react";
import wc from "../images/weatherchannel.png";
import windy from "../images/windy.webp";


!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');

let Weather = (props) => {



  
  
  return (
    <div>
        <div style={{height:'200px', display:'flex'}}>
          <div style={left}>
          <a href="https://weather.com/" target="blank">
          <img
              alt=""
              src={wc}
              // width="30"
               height="100"
              className="d-inline-block align-top"
            />
            </a>
          </div>
          <div style={right}>
          <a href="https://windy.com/" target="blank">
          <img
              alt=""
              src={windy}
               //width="200"
               height="100"
              className="d-inline-block align-top"
            />
            </a>
          </div>
          
        </div>
              
     </div>
  );
};


export default Weather;




const left = {
  float: 'left',
  width: '50%',
  overflow: 'hidden',
  margin:'auto',
  textAlign:'center'

 
}

const right=  {
  overflow: 'hidden',
  margin:'auto',
  textAlign:'left'
}