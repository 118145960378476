import React, { useState, useContext, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import { StoreContext } from "../store";
import "../App.css";
import webstat from "../images/webstat.JPG";
import Login from "./atoms/molecules/Login";
//import siegel from "../images/"


let AdBox = () => {


  let ads = [
  {Id:1, DateCreated:'2/06/23', Title:"Become a sponsor!", Detail1:"",Detail2:"", Detail3:"" , Link:"addinfo.pdf", Image:"adboard.jpg"},
  {Id:2,DateCreated:'8/15/22',Title:"Diamond Wings Supplies",  Detail1:"An independent importer of top of the line Belgian pigeon feed,grits and minerals", Detail2:"loft and breeding supplies and much more!" , Link:"DIAMONDWINGS.pdf",Image:""},
   {Id:3,DateCreated:'12/15/23',Title:"Tommy's Feeds", Detail1:"50lbs bag for $24, Custom orders welcome!",Detail2:'European Competition Tumblers for Sale.',Detail3:"", Link:"Yellow Modern Warning with Sun Rays Instagram Story.PNG",Image:"tommylogo2.jpg"},
  // {Id:4,DateCreated:'9/15/22',Title:"For Sale!", Detail1:"Wicker Baskets and 4 Holes Show Cages",Detail2:'Contact Jimmy Corso 201-450-4551.',Detail3:"", Link:"",Image:""},
  {Id:5,DateCreated:'9/15/22',Title:"Siegel Pigeons", Detail1:"",Detail2:'',Detail3:"", Link:"https://www.siegelpigeons.com/product/benzing-live-smarthub/",Image:"Benzing Smarthub 5 - SPECIAL-.jpg"},
  // {Id:6,DateCreated:'2/09/23',Title:"CAN'T MISS AUCTIONS & FUTURITY RACES", Detail1:"METUCHEN SHOOTOUT RACE & AUCTION",Detail2:'STEVE BALOG SR MEMORIAL AUCTION',Detail3:"QCM FUTURITY RACE & AUCTION", Link:"QCM&SHOOTOUTWEBAD.pdf",Image:""},
  // {Id:7,DateCreated:'6/04/23',Title:"California Classic", Detail1:"",Detail2:'',Detail3:"", Link:"CaliforniaClassicAd.png",Image:"CaliforniaLateHatchClassic.png"},
  // {Id:8,DateCreated:'10/05/23',Title:"", Detail1:"This is one auction you don't want to miss! Offering my entire collection of Wout Smeulder!",Detail2:'',Detail3:"", Link:"Ad Final 2023.jpg",Image:"Ad Final 2023small.jpg"},
  // {Id:9,DateCreated:'10/24/23',Title:"Chelsea Loft Auction", Detail1:"Chelsea Loft aquired birds from the TOP lofts like Ganus, Eijerkamp, Oscan and Linda Devries.",Detail2:'Birds available in the Auction are bred in individual cages!',Detail3:"", Link:"chelsealoftauction.pdf",Image:""},
  {Id:10,DateCreated:'10/25/23',Title:"The Tar Heel Classic OLR", Detail1:"",Detail2:'',Detail3:"", Link:"https://www.tarheelclassicrace.com/",Image:"tarheellogo2.png"},
  {Id:11,DateCreated:'11/04/23',Title:"", Detail1:"",Detail2:'',Detail3:"", Link:"https://www.facebook.com/YouCanCOUNTONCARLA",Image:"COC.jpg"},
  {Id:12,DateCreated:'12/15/23',Title:"Luxurious Handcrafted Soaps", Detail1:"",Detail2:'',Detail3:"", Link:"http://www.gotcocos.com",Image:"cjc_gotcoco_ad.png"},
  {Id:13,DateCreated:'01/10/24',Title:"", Detail1:"",Detail2:'',Detail3:"", Link:"ADFROM HEADERSEEMOREINFORMATION.docx.pdf",Image:"2024qcm.JPG"},
  {Id:14,DateCreated:'03/02/24',Title:"", Detail1:"",Detail2:'',Detail3:"", Link:"SUPER 2024 YOUNG BIRDS.pdf",Image:"superset2024.JPG"},
  {Id:15,DateCreated:'03/02/24',Title:"website starting at $200 per year", Detail1:"",Detail2:'',Detail3:"", Link:"https://www.charmedcoder.com/",Image:"charmedcoder.png"},
];

  const [shuffled, setShuffle] = useState(ads);
  const [hideAd, setHideAd] = useState(false);
  const [hideX, setHideX] = useState(false);


  useEffect(() => {
    // 👇️ only runs once
    console.log('shuffle once');

    function shuffle(array) {
      let currentIndex = array.length,  randomIndex;
  
      // While there remain elements to shuffle.
      while (currentIndex != 0) {
  
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
  
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    }
  

   setShuffle(shuffle(ads));


   
  }, []); // 👈️ empty dependencies array


  const [num, setNum] = useState(0);

  const timeDelayed = 4000;
  useEffect(() => {
    setTimeout(() => {
      if(num<shuffled.length-1)
      {
        setNum(num + 1);
        
      }
      else
      {
        setNum(0);
       //setHideX(false);        
      }
    }, timeDelayed);
  });


  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }    

const accountName = "cjccombine";
  const openFile = (fName) => {

    if(fName.includes('www'))
    {
      window.open(
        fName,
        "_blank"
      );      
    }else{
    window.open(
      `http://api.pigeonconnect.charmedcoder.com/UploadedFiles/${accountName}/posts/` + fName,
      "_blank"
    );
    }
  };

  const closeAd = () =>{

    setHideAd(true);
  }

  const renderLink = (link) => {
    return (
      <div style={{paddingTop:'5px'}}><a
      className="btn btn-primary btn-md"
      // href="src/files/Auction_ad_page_2.pdf"
      onClick={() => openFile(link)}
      role="button"
      target="blank"
    >Learn more</a></div>
    );
  };


return (

     <div className="adbox-container" style={(hideAd)? {display:'none'}:{display:'block',}} >
          <div className="card" style={{background:'white'}}>
            {(hideX)?'':
            <span style={{display:'none',fontSize:'30px',padding:'10px', top:'5px', right:'5px', color:'#CD5C5C'}} data-toggle="tooltip" data-placement="top" title="Close Ad" onClick={() => closeAd()}>X</span>}
              <div className="card-body">
            <h5 className="card-title" ><span style={{color:'#343a40', fontFamily:'Helvetica', letterSpacing:'0.1em'}}>{shuffled[num]?.Title.toUpperCase()}</span></h5>
            {shuffled[num]?.Image.length>3 ? 
             <div     onClick={() => openFile(shuffled[num]?.Link)} style={{cursor:'pointer', width:'500px'}}>
             <img style={{width:'auto',  height:'150px'}} src={`../../images/${shuffled[num]?.Image}`} alt="image not available"/>
           </div>
            :
            <p className="card-text" style={{color:'#343a40', fontSize:'15px'}}>
            <ul>
              <li style={{'list-style-type': 'none',fontFamily:'Arial', letterSpacing:'0.1em'}}>{shuffled[num]?.Detail1}</li>
              <li style={{'list-style-type': 'none', fontFamily:'Arial', letterSpacing:'0.1em'}}>{shuffled[num]?.Detail2}</li>
              <li style={{'list-style-type': 'none',  fontFamily:'Arial',letterSpacing:'0.1em'}}>{shuffled[num]?.Detail3}</li>
            </ul>  
            {(shuffled[num]?.Link.length > 3)? renderLink(shuffled[num]?.Link):''}                       
          </p>
          }     
          </div>
      </div>
    </div>
  );
};
export default AdBox;
