import React from "react";

const DeleteConfirmation = (props) => {
  return (
    <div className="edit-container">
      <div className="container">
        <label>{props.Msg}</label>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
