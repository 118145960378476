import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Home from "./components/home";
import Schedule from "./components/schedule";
import Members from "./components/members";
import Navigation from "./components/navigation";
import Races from "./components/races";
import Weather from "./components/weather";
import OtherLinks from "./components/otherlinks";
import Admin from "./components/admin";
import Directory from "./components/directory";
import Footer from "./components/atoms/molecules/Footer";
import FuturityResults from "./components/futurityresults";
import CJCResults from "./components/cjcresults";

//import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap/dist/js/bootstrap.js";
import RacesEdit from "./components/races-edit";
import StoreProvider from "./store";
import NoMatch from "./components/atoms/NoMatch";
import 'bootstrap/dist/css/bootstrap.min.css';
//import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
      <meta property="og:title" content="Central Jersey Homing Pigeon Combine - CJC." />
      <meta property="og:description" content="The Central Jersey Combine is an organization composed of local racing pigeon clubs in New Jersey and Staten Island, NY.
      We are dedicated for promoting good sportmanship among our members and well being of racing pigeons."/>
      <meta property="og:image" content="http://www.cjccombine.com/static/media/cjclogosmall.c1e3060e.png" />
      <meta property="og:url" content="www.cjccombine.com" />
      <meta property="og:type" content="website" />
    </Helmet>
    <StoreProvider>
      <div className="main">
        <Router>
          <div className="nav-wrapper">
            <Navigation></Navigation>
          </div>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path="/schedule" exact component={Schedule} />
            <Route path="/races" exact component={Races} />
            <Route path="/cjcresults" exact component={CJCResults} />
            <Route path="/futurityresults" exact component={FuturityResults} />
            <Route path="/members" component={Members} />
            <Route path="/directory" component={Directory} />
            <Route path="/weather" component={Weather} />
            <Route path="/otherlinks" component={OtherLinks} />
            <Route path="/admin" component={Admin} />
            <Route component={NoMatch} />
          </Switch>
          <Footer></Footer>
        </Router>
      </div>
    </StoreProvider>
    </HelmetProvider>
  );
}

export default App;

