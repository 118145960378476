import axios from "axios";
import React, { useState, useContext, useEffect } from "react";

import { StoreContext, initialState, SelectedYear } from "./store";
import ActionTypes from "./actionTypes";
import { findDOMNode } from "react-dom";
import urlExist from "url-exist"

const accountID = 5;
const AuthStr = "Bearer ".concat(accountID);
//const AuthStr = { AccountID: accountID, Token: initialState.Token };
const config = { headers: { Authorization: AuthStr } };

//dev
//const baseUrl = "http://localhost:64567/api/";
//prod
const baseUrl = "http://api.pigeonconnect.charmedcoder.com/api/";


export function PopulateRaceForm(raceResult, dispatch) {
  dispatch({
    actionType: ActionTypes.POPULATE_RACE_FORM,
    payload: raceResult,
  });
}
let fileReader;

export function UpdateRace(dispatch, Race) {
  dispatch({
    actionType: ActionTypes.UPDATE_RACE_OBJECT,
    payload: Race,
  });
}

export function SaveRace(Race, dispatch,authToken) {

  Race.AccountID = accountID;
  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = { headers: { Authorization: AuthStr } };

  var endPoint = baseUrl + `RaceResults/SaveRace`;
  //.get(`${baseUrl}/api/messages`)
  return axios
    .post(endPoint, Race, config)
    .then(function (response) {
      Race.GUID = response.data;
        SaveRaceFlyer(Race, dispatch,authToken);
        SaveRaceResult(Race, dispatch,authToken);
        RequestResults(dispatch, (new Date()).getFullYear(),authToken);
        GetYears(dispatch,authToken);
    })
    .catch(function (error) {
      console.log(error);
    });
}


function SaveAttachment(fd,dispatch,authToken )
{

  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: AuthStr,
    },
  };

  var endPoint = baseUrl + `FileUpload/UploadAttachmentV2`;
  return axios
    .post(endPoint, fd, config)
    .then(function (response) {

    })
    .catch(function (error) {
      console.log(error);
    });


}

export function SaveRaceFlyer(Race, dispatch,authToken) {


  if(Race.FileFlyerData === undefined ) return;


  const fd = new FormData();

  fd.append("attachmentType", 'RaceFlyers');
  fd.append("refKey", Race.GUID);
  fd.append("flyer", Race.FileFlyerData, Race.FlyerName);
  fd.append("fileName", Race.FlyerName);
  SaveAttachment(fd,dispatch, authToken);

}

export function SaveRaceResult(Race, dispatch,authToken) {


  if(Race.FileData === undefined) return;

  const fd = new FormData();

  fd.append("attachmentType", 'RaceResults');
  fd.append("refKey", Race.GUID);
  fd.append("raceresult", Race.FileData, Race.FName);
  fd.append("fileName", Race.FName);
  SaveAttachment(fd,dispatch, authToken);

}


// export function RequestResults(dispatch, selectedYear) {
//   if (selectedYear == undefined) return;
//   var endPoint = baseUrl + `RaceResults/GetResults`;

//   const config = {
//     headers: { Authorization: AuthStr },
//     params: { year: selectedYear },
//   };

//   return axios
//     .get(endPoint, config)
//     .then(function (response) {
//       dispatch({
//         actionType: ActionTypes.LOAD_RACES,
//         payload: response.data,
//       });
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// }


export function GetRaces(dispatch) {
  const races =  [{'Name':'Race1','RaceDate':new Date()}, {'Name':'Race2', 'RaceDate':new Date()}];

  const years = races.map(r=> r.RaceDate.getFullYear());

  var uniqueYears = [...new Set(years)];


  dispatch({
    actionType: ActionTypes.LOAD_RACES,
    payload: races,
  });

  dispatch({
    actionType: ActionTypes.LOAD_YEARS,
    payload: uniqueYears,
  });
}

export function DeletePost(dispatch, post, authToken) {
  var endPoint = baseUrl + `Post/Remove`;
  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = { headers: { Authorization: AuthStr } };

  return axios
    .post(endPoint, post, config)
    .then(function (response) {
      GetPosts(dispatch);
    })
    .catch(function (error) {
      ErrorHandler(error);
      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: false,
      });
    });
}

export function SavePost(dispatch, post, authToken) {
  post.AccountID = accountID;
  post.CreatedDate = new Date();
  
  var endPoint = baseUrl + `Post/SavePost`;
  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = { headers: { Authorization: AuthStr } };

  return axios
    .post(endPoint, post, config)
    .then(function (response) {
      GetPosts(dispatch);
    })
    .catch(function (error) {
      ErrorHandler(error);
      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: false,
      });
    });
}
export function SavePostWithAttachment(dispatch, post, authToken) {
  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: AuthStr,
    },
  };

  const fd = new FormData();
  fd.append("attachedTXT", post.FileData, post.FileData.name);
  fd.append("title", post.Title);
  fd.append("detail", post.Detail);
  fd.append("createdDate",(new Date()).toJSON());
  fd.append("fileName", post.Attached);

  var endPoint = baseUrl + `Post/SavePostWithUpload`;
  return axios
    .post(endPoint, fd, config)
    .then(function (response) {
      GetPosts(dispatch);
    })
    .catch(function (error) {
      ErrorHandler(error);
      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: false,
      });
    });
}


export async function GetFlyer(refKey) {

  var endpoint = baseUrl + "FileUpload/GetFlyer";

  const config = {
    headers: { Authorization: AuthStr },
    params: { refKey: refKey },
  };
  var response = await axios.get(endpoint, config)

  return response.data
}

export function GetFlyerX(dispatch, refKey) {

  var endpoint = baseUrl + "FileUpload/GetFlyer";
  const config = {
    headers: { Authorization: AuthStr },
    params: { refKey: refKey },
  };


  return axios
    .get(endpoint, config)
    .then(function (response) {

      dispatch({
        actionType: ActionTypes.LOAD_FLYER,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function GetPosts(dispatch) {
  var endpoint = baseUrl + "Post/GetPosts";

  return axios
    .get(endpoint, config)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.LOAD_POSTS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function GetAPIVersion(dispatch) {
  var endPoint = baseUrl + `Account/GetAPIVersion`;

  return axios
    .get(endPoint, config)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.GET_API_VERSION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

function ValidateRace(Race) {

  
  if (Race.FileData === null || Race.FileData ==="") return false;

  return true;
}

export function EditRace(Race, dispatch, authToken) {
  Race.AccountID = accountID;

  var endPoint = baseUrl + `RaceResults/EditRace`;

  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = { headers: { Authorization: AuthStr } };


  return axios
    .post(endPoint, Race, config)
    .then(function (response) {
      RequestResults(dispatch, new Date(Race.RaceDate).getFullYear(), authToken);
      SaveRaceFlyer(Race, dispatch,authToken);
      SaveRaceResult(Race, dispatch,authToken);

      GetYears(dispatch, authToken);
    })
    .catch(function (error) {
      ErrorHandler(error);
      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: false,
      });
    });
}

export function DeleteRace(Race, dispatch, authToken) {
  Race.AccountID = accountID;


  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  var endPoint = baseUrl + `RaceResults/Remove`;
  const config = { headers: { Authorization: AuthStr } };

  return axios
    .post(endPoint, Race, config)
    .then(function (response) {
     // GetYears(dispatch);
      RequestResults(dispatch, new Date(Race.RaceDate).getFullYear(), authToken);
     // PopulateRaceForm(initialState.ResetRace, dispatch);
    })
    .catch(function (error) {
      ErrorHandler(error);
      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: false,
      });
    });
}
export function ResetRace(Race, dispatch) {
  dispatch({
    actionType: ActionTypes.RESET_RACE,
    payload: Race,
  });
}
export function SetSelectedYear(dispatch, year) {
  dispatch({
    actionType: ActionTypes.SET_SELECTED_YEAR,
    payload: year,
  });
}

export function RequestResults(dispatch, selectedYear, authToken) {


  if (selectedYear == undefined) return;
  
  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);

  var endPoint = baseUrl + `RaceResults/GetResults`;

  const config = {
    headers: { Authorization: AuthStr },
    params: { year: selectedYear },
  };

  
  return axios
    .get(endPoint, config)
    .then(function (response) {

      dispatch({
        actionType: ActionTypes.LOAD_RACES,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function GetYears(dispatch, authToken) {
  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = { headers: { Authorization: AuthStr } };

  var endPoint = baseUrl + `RaceResults/GetYears`;

  return axios
    .get(endPoint, config)
    .then(function (response) {

      dispatch({
        actionType: ActionTypes.GET_YEARS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

//ROSTERS
export function SaveRoster(dispatch, Roster, authToken) {
  Roster.AccountID = accountID;
  var endPoint = baseUrl + `ClubRoster/SaveRoster`;
  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = { headers: { Authorization: AuthStr } };
  return axios
    .post(endPoint, Roster, config)
    .then(function (response) {
      GetRosters(dispatch);
    })
    .catch(function (error) {
      ErrorHandler(error);
      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: false,
      });
    });
}

export function GetRosters(dispatch) {
  var endPoint = baseUrl + `ClubRoster/GetRosters`;
  return axios
    .get(endPoint, config)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.LOAD_ROSTERS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}
export function DeleteRoster(dispatch, Roster, authToken) {
  var endPoint = baseUrl + `ClubRoster/Remove`;
  const AuthStr = "Token ".concat(authToken.Token + "_" + accountID);
  const config = { headers: { Authorization: AuthStr } };

  return axios
    .post(endPoint, Roster, config)
    .then(function (response) {
      GetRosters(dispatch);
    })
    .catch(function (error) {
      ErrorHandler(error);
      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: false,
      });
    });
}
export function Logout(dispatch) {
  dispatch({
    actionType: ActionTypes.UPDATE_AUTH_TOKEN,
    payload: {},
  });
  dispatch({
    actionType: ActionTypes.UPDATE_LOGIN,
    payload: false,
  });
}
export function Logging(dispatch, userName, password) {
  var endPoint = baseUrl + `Account/Authenticate`;

  //const AuthStr = "Bearer ".concat(accountID); //+ ", Token ".concat(initialState.Token);
  const config = {
    headers: { Authorization: AuthStr },
    params: { userName, password },
  };
  return axios
    .get(endPoint, config)
    .then(function (response) {
      dispatch({
        actionType: ActionTypes.UPDATE_AUTH_TOKEN,
        payload: response.data,
      });

      dispatch({
        actionType: ActionTypes.UPDATE_LOGIN,
        payload: response.data.Token.length > 0 ? true : false,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function UpdateLogin(dispatch, userName, password) {
  var endPoint = baseUrl + `Account/Login`;
  var x = " Token";
  const AuthStr = "Bearer ".concat(accountID, x); //+ ", Token ".concat(initialState.Token);
  const config = {
    headers: { Authorization: AuthStr },
    params: { userName, password },
  };
  return axios
    .get(endPoint, config)
    .then(function (response) {})
    .catch(function (error) {
      console.log(error);
    });
}

export function SendMessage(dispatch, message, authToken) {

  message.AccountID = accountID;
  message.Recipient = (message.Recipient=="")?  "charmedcoder@gmail.com" : message.Recipient ;
  message.Subject = "Someone is contacting you from the CJC Contact page."
  message.Source = "cjc-web";
  var endPoint = baseUrl + `Account/SendMessage`;

  return axios
    .post(endPoint, message, config)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

function ErrorHandler(error) {
  var msg = "";
  if (error.toString().includes("401")) {
    msg = "Invalid Authentication or token expired.  Please Login to continue.";
  } else {
    msg = error.toString();
  }
}

export function ConvertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
}