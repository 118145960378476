import React from "react";
import { BrowserRouter, Link } from "react-router-dom";

let AdminCards = () => {
  //const token = "xyz";

  const goToRoster = () => {
    BrowserRouter.push("/roster");
  };
  return (
    <React.Fragment>
       <div class="card">
        <h5 class="card-header">Manage Races</h5>
        <div class="card-body">
          <h5 class="card-title">Add, Edit, or Delete Races</h5>
          <p class="card-text">
            You can add, edit, delete races. Edit only works for updating race
            info, if you need to update the attached race, delete the race and
            re-add it. If you upload winspeed result in ".txt" format the race
            fields will be automatically populated. Another new feature,
            clicking on the race row will show you the top 3 birds on the list.
          </p>
          <Link to={`/futurityresults`}>
            <a href="#" class="btn btn-primary">
              Futurity Results
            </a>
          </Link>
        </div>
      </div>
      <div class="card">
        <h5 class="card-header">Manage Announcements</h5>
        <div class="card-body">
          <h5 class="card-title">Add, Edit, or Delete announcement</h5>
          <p class="card-text">
            You can add, edit, delete announcement. Your announcement can just
            be all text or with attachment. Announcements are sorted by created
            date, latest on top. You're allowed to pick one announcement that
            can stay on top of the list by clicking on the checkbox for "keep
            this on top".
          </p>
          <Link to={`/home`}>
            <a href="#" class="btn btn-primary">
              Go to announcements
            </a>
          </Link>
        </div>
      </div>
      <div class="card">
        <h5 class="card-header">Manage Directory</h5>
        <div class="card-body">
          <h5 class="card-title">Add, Edit or Delete from directory</h5>
          <p class="card-text">
            You can add, edit, update directory list. You can also enable notification
            so your members can receive an email when new announcement or result
            is posted on the website.
          </p>
          <Link to={`/directory`}>
            <a href="#" class="btn btn-primary">
              Go to directory
            </a>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AdminCards;
