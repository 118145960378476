import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/banner.png";
import pigeonImage from "../images/cjclogosmall.png";
import "../App.css";
import {
  Navbar,
  NavItem,
  NavDropdown,
  MenuItem,
  Nav,
  NavbarBrand,
} from "react-bootstrap";

import cjcLogoMain from "../images/cjclogolong.png";
import cjcLogoMobile from "../images/cjclogosmall.png"

import { StoreContext } from "../store";
import { faBold, faFlagUsa } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UpdateLogin, Logout } from "../actions";
import { Link } from "react-router-dom";
import ContactUsModal from "./atoms/molecules/ContactUsModal";
import AdBox from "./adbox";


const Navigation = () => {
  const { IsLogin, APIVersion, dispatch } = useContext(StoreContext);

  const [showContactModal, setShowContactModal] = useState(false);

  const hideModalClick = () => {
    setShowContactModal(false);
  };

  const handleLogout = () => {
    Logout(dispatch);
  };

  const handleShowContact = () => {
    setShowContactModal(true);
  };

  const handleLogin = () => {
    //UpdateLogin(dispatch, true);
  };
  useEffect(() => {
    //console.log("is login" + IsLogin);
  }, IsLogin);

  
  return (
    <div style={{background:'#343a40'}} >    
    <div style={{ backgroundImage: 'linear-gradient(#ff4d00,#ff6700,#ff8100, #ffa700)', padding:'5px'}}>
        <img id="cjcLogoMain" src={cjcLogoMain}  alt=""/>

        <img id="cjcLogoMobile" src={cjcLogoMobile}  alt=""/>

    </div>
      
      <Navbar expand="sm" style={containerNav}>
        <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto navbar">
            {IsLogin ? (
              <Nav.Link as={Link} to="/admin">
                <span className="manage-view">Admin</span>
              </Nav.Link>
            ) : (
              ""
            )}
            {IsLogin ?
             <Nav.Link as={Link} to="">
                <span style={{color:'#CD5C5C'}} onClick={() => handleLogout()}>
            Logout
            {/* <Link to="/admin">
              <mark className="text-danger">Logout</mark>
            </Link> */}
          </span>
              </Nav.Link>
              :''
            }
            {/* <Navbar.Brand href="#">
            <img
              alt=""
              src={pigeonImage}
              width="auto"
              height="50px"
              className="d-inline-block align-top"
            />
            </Navbar.Brand> */}
            <Nav.Link as={Link} to="/home">
             Announcements
            </Nav.Link>
            {/* <NavDropdown title="Schedules" id="basic-nav-dropdown" className="bg-dark">
              <NavDropdown.Item  as={Link} to="/schedule"><span style={{color:'black'}}>CJC</span></NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/futurityresults"><span style={{color:'black'}}>Auctions/Futurities</span></NavDropdown.Item>
            </NavDropdown> */}        
            <NavDropdown title="Schedules/Results" id="basic-nav-dropdown">
              {/* <NavDropdown.Item   href="https://mypigeons.benzing.live/usa/en/results/2023/o-1-1-central-jersey-homing-pigeon-combine/"
              target="_blank"><span style={{color:'black'}}>Combine Results</span></NavDropdown.Item>   */}
              <NavDropdown.Item as={Link} to="/futurityresults"><span style={{color:'black'}}>Auctions/Futurities</span></NavDropdown.Item>     
              {/* <NavDropdown.Item   href="https://mypigeons.benzing.live/usa/en/results/2023/o-1-1-central-jersey-homing-pigeon-combine/#competition"
              target="_blank"><span style={{color:'black'}}>Combine Overall Results</span></NavDropdown.Item>               */}
              <NavDropdown.Item as={Link} to="/cjcresults"><span style={{color:'black'}}>(CJC) Combine</span></NavDropdown.Item>     
              {/* <NavDropdown.Item  as={Link} to="/schedule"><span style={{color:'black'}}>Combine Schedule</span></NavDropdown.Item>          */}
            </NavDropdown>
            <NavDropdown title="Archives" id="basic-nav-dropdown" >
              <NavDropdown.Item href="http://www.cjccombine.com/archives/"
              target="_blank"><span style={{color:'black'}}>Archive (1997-2009)</span></NavDropdown.Item>
              <NavDropdown.Item href="http://www.cjccombine.com/archives2/"
              target="_blank"><span style={{color:'black'}}>Archive (2010-2021)</span></NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/directory">
              Directory
            </Nav.Link>  
            <NavDropdown title="Contact Us" id="basic-nav-dropdown">
              <NavDropdown.Item><span style={{color:'black'}} onClick={() => handleShowContact()}>Contact Us</span></NavDropdown.Item>
              <NavDropdown.Item><span  style={{color:'black'}} onClick={() => handleShowContact()}>Join A Pigeon Club</span></NavDropdown.Item>
              <NavDropdown.Item><span  style={{color:'black'}} onClick={() => handleShowContact()}>Opt In To Receive Notifications</span></NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link>
              <span onClick={() => handleShowContact()}>Contact Us</span>
            </Nav.Link> */}
            <NavDropdown title="Links" id="basic-nav-dropdown">
              <NavDropdown.Item  as={Link} to="/weather"><span style={{color:'black'}}>Weather</span></NavDropdown.Item>
              <NavDropdown.Item href="/otherlinks"><span style={{color:'black'}}>Pigeon Related Links</span></NavDropdown.Item>
              <NavDropdown.Item href="https://mypigeons.benzing.live/usa/en/" target="_blank"><span style={{color:'black'}}>MyPigeons/Benzing</span></NavDropdown.Item>              
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div style={{textAlign:'center'}}>
      {IsLogin ? '':<AdBox></AdBox>}
       
      </div>
        

      {showContactModal ? (
        <ContactUsModal
          show={showContactModal}
          closeModal={() => hideModalClick()}
        />
      ) : null}
    </div>
  );
};
export default Navigation;

const txtLogo = {
  fontSize: '20px',
  color: "#36ace4",
  textAlign: "center",
    fontFamily:"Verdana"
}

const containerNav =
{
//   borderTopRightRadius:'20px',
//   borderTopLeftRadius:'20px',
   
  width:'100%',
    verticalAlign:'middle',
  background:' #343a40'
  //backgroundImage: 'linear-gradient( #ffa700,#343a40,#343a40, #343a40)'
  

}

