import React from "react";

const NoMatch = () => {
  return (
    <div className="container">
      <div className="spacer"></div>
      <div>
        <h4>
          Oops! The page you’re looking for can’t be found. You may have
          mistyped the address or the page may have moved.
        </h4>
      </div>
      <div className="spacer">
        <a href="http://cjccombine.com">
          <span className="font-weight-bold">Go to main page</span>{" "}
        </a>
      </div>
    </div>
  );
};
export default NoMatch;
