/* eslint-disable */

import React, { useState, useContext, useEffect } from "react";
import { format } from 'date-fns';
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from 'react-bootstrap/Dropdown';
import { RequestResults,ConvertUTCDateToLocalDate, GetYears, SaveRace, GetFlyer, axiosTest } from "../actions";
import { StoreContext } from "../store";
import queryString from "query-string";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AddEditPostModal from "./atoms/molecules/AddEditPostModal";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import AddEditRaceModal from "../components/atoms/molecules/AddEditRaceModal";

const accountName = "cjccombine";

const FuturityResults = (props) => {
  const { Races, Years, IsLogin, dispatch, AuthToken, Flyer } = useContext(StoreContext);

  //const values = queryString.parse(window.location.search);


  let [isAdmin, setIsAdmin] = useState(false);
  let [selectedYear,setSelectedYear] = useState((new Date()).getFullYear());
  // const [fileName, setFileName] = useState("");
  // const [fileData, setFileData] = useState("");
  // const [raceName, setRaceName] = useState("");
  const [years, setYears] = useState([]);
  const [winner, setWinner]=useState("");
  const [formData, setFormData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [race, setRace] = useState();
  const [clickedFlyer, setClickedFlyer] = useState(false);
  const [flyerLink, setFlyerLink] = useState({});
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setIsAdmin(IsLogin);
  }, [IsLogin]);


  useEffect(() => {


    if(JSON.stringify(Flyer) === '{}') return;

    if(clickedFlyer)
    {
      var fullpath = `http://api.pigeonconnect.charmedcoder.com/UploadedFiles/${accountName}/flyer/` +Flyer.FileName;

    //   setTimeout(() => {
    //     window.open(fullpath, '_top');
    // })
    
      // window.open(
      //    fullpath,
      //    "_blank"
      // );
   
      setClickedFlyer(false);
    }
    
  }, [Flyer]);
  

  const addEditClick = (race) => {

    
    setRace(race);
    setFormData(race);
    setShowModal(true);
    setModalTitle((race.GUID=="") ? "Add race": "Edit Race");
    setDeleteFlag(false);
  };

const openFile = (fName) =>
{

  if(fName.includes('http'))
  {
    window.open(
      fName,
      "_blank"
    );      
  }else{

    var fullpath = `http://api.pigeonconnect.charmedcoder.com/UploadedFiles/${accountName}/raceresults/` +fName;

    window.open(
       fullpath,
       "_blank"
    );
  
  }



}





  async function openFlyer(refKey)
  {

    let win = window.open();
    const Flyer =  await GetFlyer(refKey);

    if(Flyer.FileName === null)
    {
      win.close();
    }else
    {
      var fullpath = `http://api.pigeonconnect.charmedcoder.com/UploadedFiles/${accountName}/raceflyers/` +Flyer.FileName;
   
      win.location = fullpath;
      win.target = '_blank'
    
    }
    

  }

  const deleteRaceClick = (race) => {
    
    setFormData(race);
    setModalTitle("Please Confirm");
    setShowModal(true);
    setDeleteFlag(true);
    // setRaceData(race);
  };

  useEffect(() => {
  
    //GetRaces(dispatch);
    RequestResults(dispatch,selectedYear,AuthToken);
    GetYears(dispatch,AuthToken);

  
  }, [selectedYear]);

  const initialRace = {
    GUID: "",
    RaceName: "",
    Winner: "",
    FName:"",
    FileData:""
  };
  
  const ReadFile = (sender) => {
    
    let file = sender.target.files[0];
    
    setFileName(file.name);
    setFileData(file);
  };

  const handleSave = () => {

    var currentTime = new Date()
    var year = currentTime.getFullYear()

    let race = {
      GUID: "",
      Name: raceName,
      Year:year,
      FileData:fileData,
      Winner:winner,
      FileName:fileName
    };
    SaveRace(race, dispatch, AuthToken);
    setFileName('');
    setFileData('');
    
  };
 
  const handleCancel = (year)=>
  {

    console.log('cancel');

  }

  const renderEditDeleteBtn = (race) => {
    return (
      <div className="container text-right">
        <span className="m-2">
          <FontAwesomeIcon
            icon={faEdit}
            title="Edit Race"
            onClick={() => addEditClick(race)}
          />
        </span>
        <span onClick={() => deleteRaceClick(race)}>
          <FontAwesomeIcon icon={faTrashAlt} title="Delete Post" />
        </span>
      </div>
    );
  };



  const renderAdminBtn = () => {
    return (
      <div class="row mt-2" >
        <div class="col text-left">
          <i class="fas fa-plus"></i>
          <Button onClick={() => addEditClick(initialRace)}>
            <FontAwesomeIcon icon={faPlus} title="Add Race Result" />
            <span> Add Race</span>
          </Button>
          {showModal ? (
            <AddEditRaceModal
              show={showModal}
              closeModal={() => setShowModal(false)}
              FormData={formData}
              Race = {race}
              title={modalTitle}
              toDelete={deleteFlag}
            ></AddEditRaceModal>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

const renderRaceForm = () =>
{

  return(

    <div style={{background:'gray', padding:'20px'}}>
    
    {showModal ? (
            <AddEditRaceModal
              show={showModal}
              closeModal={() => setShowModal(false)}
              FormData={formData}
              title={modalTitle}
              toDelete={deleteFlag}
            ></AddEditRaceModal>
          ) : (
            ""
          )}
    </div>

  );
}

  return (
    <div style={{width:'90%', margin:'auto'}}>
      
         <div style={homeContainer}>
            <p>Futurity Races            
          </p>
          <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Select Year
      </Dropdown.Toggle>

      <Dropdown.Menu>
        
        {Years.map( (year) =>{
          return(
            <Dropdown.Item onClick={()=>setSelectedYear(year)}>{year}</Dropdown.Item>  
          )        
        })
        }
      </Dropdown.Menu>
    </Dropdown>
    <div> {isAdmin ? renderAdminBtn() : ""}</div>
    <caption>{selectedYear}</caption>
          <Table striped bordered hover size="sm">
      <thead>
        <tr>
          {isAdmin?<th></th>:''}
          <th>Races</th>
          {/* <th>Date</th> */}
          <th>Winners</th>
          <th>Results</th>
        </tr>
      </thead>
      <tbody>
        {Races.filter(r=>r.IsCombine!=true).sort(function(a,b){
  return new Date(b.RaceDate) - new Date(a.RaceDate)
}).map((race,index) =>{

          return(
            <tr key={index}>
              {isAdmin? <td style={{marginLeft:'auto', marginRight:'auto'}}>{renderEditDeleteBtn(race)}</td> :''}
              <td style={raceNameFormat} onClick ={() => openFlyer(race.GUID)}>{race.RaceName}</td>
              {/* <td>{race.RaceDate}</td> */}
              <td style={winnerFormat}>{race.Winner}</td>              
              <td style={{cursor:'pointer'}}><a 
              onClick={() => openFile((race?.Link?.length>5) ? race.Link : race.FName)}
              >
                {(race.FName=='')? ''
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
  <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
</svg>
                }
      
</a></td>
            </tr>
          );
        })}       
      </tbody>
    </Table>


      </div>
      </div>
  );
};

export default FuturityResults;


const raceNameFormat = 
{
 fontSize:'16px',
 verticalAlign:'middle',
 cursor:'pointer',
 textAlign:'left'

}

const winnerFormat =
{
  fontSize:'16px',
  fontWeight:'bolder',
  verticalAlign:'middle'

}

const homeContainer = {
  fontSize: 30,
  color: "#343a40",
  textAlign: "center",
    fontFamily:"Helvetica",
    letterSpacing:'1px'
}

const homeContainer2 = {
  fontSize: 30,
  color: "#343a40",
  textAlign: "center",
    fontFamily:"Fantasy"
}
const postContainer = {
  fontSize: 20,
  color: "#343a40",
  textAlign: "left",
    fontFamily:"Helvitica"
}
const postTitle ={
  fontSize:'20px',
  fontWeight:'bold',
  fontFamily:"Helvetica"
};
const postDetail ={};

const liberationInfo ={
  
  border: '0px green solid',
  padding:'20px',
  
  
};

const liberationInfoSpan = {
  backgroundColor:'#36ace4'
};