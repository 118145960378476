import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";
import "../App.css";
import AdminCards from "../components/atoms/molecules/AdminCards";
import Login from "./atoms/molecules/Login";

let Admin = () => {
  const { IsLogin } = useContext(StoreContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="admin-container">
      {IsLogin ? <AdminCards></AdminCards> : <Login></Login>}
    </div>
  );
};
export default Admin;
