import React from "react";

class RacesEdit extends React.Component {
  render() {
    return (
      <div className="races-container">
        <h4>Races</h4>
        <form>
          <label>
            Date:
            <input type="date" name="date" />
          </label>
          <label>
            Winner:
            <input type="text" name="winner" />
          </label>
          <label>
            Race:
            <input type="text" name="race" />
          </label>
          <label>
            <input type="file" name="file" />
          </label>
          <label>
            <input type="button" name="submit" value="Submit" />
          </label>
        </form>
      </div>
    );
  }
}

export default RacesEdit;

const raceList = [
  {
    Id: 1,
    Date: "2019-09-08",
    Winner: "Crown",
    Race: "1st 150m YB Carlisle PA",
    Result: "view result"
  },
  {
    Id: 2,
    Date: "2019-09-08",
    Winner: "Hillside",
    Race: "2nd 150m YB Carlisle PA",
    Result: "view result"
  }
];
