

import React from "react";
import { Helmet } from 'react-helmet-async';
import wc from "../images/weatherchannel.png";
import windy from "../images/windy.webp";

let OtherLinks = (props) => {



  
  
  return (
    <div>
        <Helmet>
          <title>Racing Pigeons</title>
          <meta name="description" content="Useful apps for racing pigeons." />
          <meta
          name="keywords"
          content="Pigeon, Racing Pigeon, Pigeon Racing, Racing Homers, Pigeon Sports, NJ Pigeons, NJ Racing Pigeons"
        />
        </Helmet>
        <div style={{height:'200px', padding:'20px'}}>
        <p>
          <a href="http://pigeonconnect.com/" target="blank"><span style={{fontSize:'20px', color:'black'}}>Pigeon Marketplace</span></a>
          </p>
          <p>
          <a href="http://pedigree.charmedcoder.com/" target="blank"><span style={{fontSize:'20px', color:'black'}}>Free Pedigree Software</span></a>
          </p>
          <p>
          <a href="http://pigeonconnect.com/pigeonspeedcalculator/" target="blank"><span style={{fontSize:'20px', color:'black'}}>Pigeon Speed Calculator</span></a>
          </p>
          <p>
          <a href="https://www.swpc.noaa.gov/products/3-day-forecast" target="blank"><span style={{fontSize:'20px', color:'black'}}>Geo Magnetic Activity (K Index forecast)</span></a>
          </p>
          <p>
          <a href=" https://www.swpc.noaa.gov/products/station-k-and-indices" target="blank"><span style={{fontSize:'20px', color:'black'}}>K Index</span></a>
          </p>
        </div>
              
     </div>
  );
};


export default OtherLinks;




const left = {
  float: 'left',
  width: '50%',
  overflow: 'hidden',
  margin:'auto',
  textAlign:'center'

 
}

const right=  {
  overflow: 'hidden',
  margin:'auto',
  textAlign:'left'
}