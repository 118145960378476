import React, { useContext, useEffect, useState } from "react";
import { StoreContext, initialState } from "../../../store";
import ModalWrapper from "../modal/Modal";
import DeleteConfirmation from "../molecules/DeleteConfirmation";
import { SavePost, SavePostWithAttachment, DeletePost } from "../../../actions";
import Upload from "../upload/Upload";

const AddEditPostModal = (props) => {
  const { dispatch, AuthToken } = useContext(StoreContext);
  console.log("load addeditpost modal");
  //roster form
  const [IsPinned, setIsPinned] = useState("");

  const [Title, setTitle] = useState("");
  const [Detail, setDetail] = useState("");
  const [Link, setLink] = useState("");
  const [Attached, setAttachment] = useState("");

  const post = props.FormData;
  const [FormData, setFormData] = useState("");
  const [FileData, setFileData] = useState("");

  const ReadFile = (sender) => {
    let file = sender.target.files[0];
    setAttachment(file.name);
    setFileData(file);
  };

  //this is triggered when upload file is used or when racedata already exists
  useEffect(() => {
    setTitle(post.Title);
    setDetail(post.Detail);
    setLink(post.Link);
    setFormData(post);
    setAttachment(post.Attached);
    setIsPinned(post.IsPinned);
  }, [post.Title]);

  //redundant maybe create modal wrapper
  const deleteConfirmed = (event) => {
    DeletePost(dispatch, post, AuthToken);
    props.closeModal();
  };

  const handleSave = () => {
    let Post = {
      GUID: post.GUID,
      Title: Title,
      Detail: Detail,
      Link:Link,
      Attached: Attached,
      IsPinned: IsPinned,
    };

    if (Attached == "" || Post.GUID != "") SavePost(dispatch, Post, AuthToken);
    else {
      Post.FileData = FileData;
      SavePostWithAttachment(dispatch, Post, AuthToken);
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    let valid = true;
    if (Title == "" && Detail == "") {
      valid = false;
    }

    if (valid) handleSave();

    return valid;
  };
  //end redundant

  const renderForm = () => {
    return (
      <div className="edit-container">
        <div className="container">
          {/* hide this on edit */}
          {FormData.GUID == "" ? (
            <div className="upload">
              <input
                type="file"
                id="file"
                onChange={(sender) => ReadFile(sender)}
              />
              <label for="file">{Attached}</label>
            </div>
          ) : (
            <span style={{ color: "red" }}>
              If you need to re-upload file, delete this post and just add a New
              Post
            </span>
          )}
        </div>
        <div className="container">
          <form>
            <ul className="flex-outer">
              <li>
                <label for="race">Title</label>
                <input
                  type="text"
                  id="title"
                  placeholder="Enter Header"
                  value={Title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </li>
              <li>
                <label for="detail">Detail</label>

                <textarea
                  className="detail-ta"
                  id="detail"
                  value={Detail}
                  onChange={(e) => setDetail(e.target.value)}
                  placeholder="Enter Detail"
                />
              </li>
              <li>
                <label for="link">Link</label>

                <textarea
                  className="link-ta"
                  id="link"
                  value={Link}
                  onChange={(e) => setLink(e.target.value)}
                  placeholder="Enter Link"
                />
              </li>
              <li>
                <input
                  type="checkbox"
                  id="pinIt"
                  checked={IsPinned}
                  style={{ width: 25, height: 25 }}
                  onClick={() => setIsPinned(!IsPinned)}
                />
                <label for="cbHideRoster">Keep this on Top</label>
              </li>
            </ul>
          </form>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      title={props.title}
      handleOnClick={props.toDelete ? deleteConfirmed : validateForm}
      show={props.show}
      closeModal={props.closeModal}
    >
      {props.toDelete ? (
        <DeleteConfirmation
          Msg={"Are you sure you want to delete this post?"}
        />
      ) : (
        renderForm()
      )}
    </ModalWrapper>
  );
};
export default AddEditPostModal;
