import React from "react";

class Members extends React.Component {
  render() {
    const renderMembersTR = memberList.map((item, index) => {
      return (
        <tr>
          <th scope="row">{index + 1}</th>
          <td>{item.name}</td>
          <td>{item.loftName}</td>
        </tr>
      );
    }).sort((a, b) => a.name > b.name);
    return (
      <div className="table-container">
        <h4>Members</h4>
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Loft Name</th>
            </tr>
          </thead>
          <tbody>{renderMembersTR}</tbody>
        </table>
      </div>
    );
  }
}

export default Members;

const memberList = [
  { name: "John Baginski", loftName: "Crown" },
  { name: "Vinnie Torre", loftName: "Hillside Loft" },
];
