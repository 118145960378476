import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId:'GTM-W7KTNMDS'
}
TagManager.initialize(tagManagerArgs);
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
