export const ActionTypes = {
  LOAD_RACES: "LOAD_RACES",
  LOAD_YEARS: "LOAD_YEARS",
  LOAD_POSTS: "LOAD_POSTS",
  LOAD_FLYER: "LOAD_FLYER",
  POPULATE_RACE_FORM: "POPULATE_RACE_FORM",
  GET_RACE_INFO: "GET_RACE_INFO",
  SAVE_RACE: "SAVE_RACE",
  UPDATE_RACE_OBJECT: "UPDATE_RACE_OBJECT",
  RESET_RACE: "RESET_RACE",
  GET_API_VERSION: "GET_API_VERSION",
  LOAD_ROSTERS: "LOAD_ROSTERS",
  SAVE_ROSTER: "SAVE_ROSTER",
  DELETE_ROSTER: "DELETE_ROSTER",
  UPDATE_LOGIN: "UPDATE_LOGIN",
  GET_YEARS: "GET_YEARS",
  SET_SELECTED_YEAR: "SET_SELECTED_YEAR",
  UPDATE_AUTH_TOKEN: "UPDATE_AUTH_TOKEN",
  SEND_MESSAGE: "SEND_MESSAGE",
};

export default ActionTypes;
