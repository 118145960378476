import React, { useContext, useState, useEffect } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Logging } from "../../../actions";
import { StoreContext } from "../../../store";

export default function Login() {
  const { AuthToken, dispatch } = useContext(StoreContext);

  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [btnSubmitText, setBtnSubmitText] = useState("Submit");

  function validateForm() {
    return userName.length > 0 && password.length > 0;
  }

  useEffect(() => {
    if (AuthToken == null) setLoginStatus("Invalid Credentials");

    setBtnSubmitText("Submit");
  }, AuthToken);
  const handleSubmit = (event) => {
    event.preventDefault();
    //UpdateLogin(dispatch, userName, password);
    setBtnSubmitText("Processing...");

    if (userName.length > 1 && password.length > 1) {
      Logging(dispatch, userName, password);
    } else {
      setLoginStatus("Invalid Credentials. Try Again.");
    }
  };

  // function handleSubmit(event) {
  //   event.preventDefault();
  //   if (userName == "admin" && password == "acepigeon")
  //     UpdateLogin(dispatch, false);
  //   else alert("invalid username or password");
  // }

  return (
    <div className="Login">
      <form>
        <FormGroup controlId="userName" bsSize="large">
          <FormLabel>User ID</FormLabel>
          <FormControl
            autoFocus
            type="text"
            //type="userName"
            value={userName}
            onChange={(e) => setuserName(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button
          block
          bsSize="large"
          disabled={!validateForm()}
          type="submit"
          onClick={handleSubmit}
        >
          {btnSubmitText}
        </Button>
        <span className="text-danger">{loginStatus}</span>
      </form>
    </div>
  );
}
