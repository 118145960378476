import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";

import AddEditRosterModal from "./atoms/molecules/AddEditRosterModal";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrashAlt, faCloudMeatball } from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import { GetRosters } from "../actions";
import phone from "../images/telephone.svg";
import email from "../images/envelope.svg";
import link from "../images/link.svg";
import ContactUsModal from "./atoms/molecules/ContactUsModal";


let Directory = () => {
  const { Rosters, IsLogin, dispatch, Roster } = useContext(StoreContext);

  //const values = queryString.parse(window.location.search);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    setIsAdmin(IsLogin);
  }, [IsLogin]);

  useEffect(() => {
    GetRosters(dispatch);
  }, []);

  const [showContactModal, setShowContactModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [fieldData, setFieldData] = useState(Roster);
  const [contactTarget, setContactTarget] =useState("");
  
  //handlers

  const hideModalClick = () => {
    setShowModal(false);
  };
  const handleShowContact = (contactInfo) => {
    setShowContactModal(true);
    setContactTarget(contactInfo);
  };
  
  const hideContactModalClick = () => {
    setShowContactModal(false);
  };

  const addNewClick = () => {
    setShowModal(true);
    setModalTitle("Add Member/Club");
    setFieldData({ GUID: "", Name: "", LoftName: "", Website:"", Email: "", Phone: "" });
    // setDeleteFlag(false);
  };
  const addEditClick = (item) => {
    setFieldData(item);
    setShowModal(true);
    setDeleteFlag(false);
    setModalTitle("Edit Race");
  };
  const deleteClick = (roster) => {
    setModalTitle("Please Confirm");
    setShowModal(true);
    setDeleteFlag(true);
    setFieldData(roster);
  };
  //end handlers

  const renderAdminBtn = () => {
    return (
      <div class="row">
        <div class="col text-left">
          <i class="fas fa-plus"></i>
          <Button onClick={() => addNewClick()}>
            <FontAwesomeIcon icon={faPlus} title="Add member" />
            <span> Add member</span>
          </Button>
          {showModal ? (
            <AddEditRosterModal
              title={modalTitle}
              show={showModal}
              closeModal={() => hideModalClick()}
              toDelete={deleteFlag}
              formData={fieldData}
            ></AddEditRosterModal>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const addEdit = (item) => {
    return (
      <React.Fragment>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            title="Edit Roster"
            onClick={() => addEditClick(item)}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrashAlt}
            title="Delete Roster"
            onClick={() => deleteClick(item)}
          />
        </td>
      </React.Fragment>
    );
  };

  const contactInfo = (item) => {
    return (
      <React.Fragment>
        <td>{item.Email}</td>
        <td>{item.Phone}</td>
      </React.Fragment>
    );
  };

  const renderNoMembersTR = () => {
    return (
      <tr>
        <td>No Members Found</td>
      </tr>
    );
  };
  let counter = 0;
  const renderMembersTR = Rosters.sort((a, b) => a.Name > b.Name ? 1 : -1).map((item, index) => {
    if (!isAdmin && item.IsRosterVisible) return null;

    counter++;
    return (
      <tr>
        {isAdmin ? addEdit(item) : ""}
        <td>{item.Name}</td>
        <td>{(item.Website == "")?'':
        <a href={`//${item.Website}`} target="blank"><img
              alt=""
              src={link}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
      </a>}
</td>
        {(isAdmin) ?'':
         <td  onClick={() => handleShowContact(item)}>  
          {(item.Email != "")?
          <img
              alt=""
              src={email}
              width="20"
              height="20"
              className="d-inline-block align-top"
              
            />:''}
            </td>
        }
        {isAdmin ? contactInfo(item) : ""}
      </tr>
    );
  });

  return (
    <div className="table-container">    
      {showContactModal ? (
        <ContactUsModal
          show={showContactModal}
          closeModal={() => hideContactModalClick()}
          contactInfo = {contactTarget}
        />
      ) : null}
      <div class="row mb-1">
        <div class="col">{isAdmin ? renderAdminBtn() : ""}</div>
      </div>
      <table class="table">
        <caption>Directory ({counter})</caption>
        <thead class="table-header">
          <tr>
            {isAdmin ? <th scope="col">edit</th> : ""}
            {isAdmin ? <th scope="col">delete</th> : ""}
            <th scope="col">Name</th>            
            <th scope="col">Website</th>
            {isAdmin ? '':<th scope="col">Contact</th>}
            {isAdmin ? <th scope="col">Email</th> : ""}
            {isAdmin ? <th scope="col">Phone</th> : ""}
          </tr>
        </thead>
        <tbody>
          {members.length > 0 ? renderMembersTR : renderNoMembersTR()}
        </tbody>
      </table>
    </div>
  );
};


const members = [{"Name":"Greater Elizabeth Pigeon Club", "BandInitials":"GE", "Website":"http://www.pigeonconnect.com/club/Posts?clubId=1"},
  {"Name":"Old Linden Pigeon Club", "BandInitials":"OLC", "Website":"http://www.pigeonconnect.com/club/Posts?clubId=2"}]
  
export default Directory;

const memberList = [
  { name: "John Baginski", loftName: "Crown" },
  { name: "Vinnie Torre", loftName: "Hillside Loft" },
];
