import React, { useContext, useEffect, useState } from "react";
import { StoreContext, initialState } from "../../../store";
import ModalWrapper from "../modal/Modal";
import DeleteConfirmation from "../molecules/DeleteConfirmation";
import { SaveRoster, DeleteRoster } from "../../../actions";

const AddEditRosterModal = (props) => {
  const { dispatch, AuthToken } = useContext(StoreContext);

  //roster form
  const [IsRosterVisible, setIsRosterVisible] = useState(false);
  const [Name, setName] = useState("");
  const [LoftName, setLoftName] = useState("");
  const [Email, setEmail] = useState("");
  const [IsEmailVisible, setIsEmailVisible] = useState(true);
  const [Phone, setPhone] = useState("");
  const [IsPhoneVisible, setIsPhoneVisible] = useState(true);
  const [SubscribedNotification, setSubscribedNotification] = useState(false);
  const [Website, setWebsite] = useState("");

  const roster = props.formData;
  const [FormData, setFormData] = useState("");

  //this is triggered when upload file is used or when racedata already exists
  useEffect(() => {
    setName(roster.Name.trim());
    setLoftName(roster.LoftName.trim());
    setWebsite(roster.Website.trim());
    setEmail(roster.Email.trim());
    setPhone(roster.Phone.trim());
    //setIsEmailVisible(roster.IsEmailVisible);
    //setIsPhoneVisible(roster.IsPhoneVisible);
    setSubscribedNotification(roster.SubscribedNotification);
    setIsRosterVisible(roster.IsRosterVisible);
    setFormData(roster);
  }, [roster.Name]);

  //redundant maybe create modal wrapper
  const deleteConfirmed = (event) => {
    DeleteRoster(dispatch, roster, AuthToken);
    props.closeModal();
  };

  const handleSave = () => {
    let Roster = {
      GUID: roster.GUID,
      Name: Name,
      Website: Website,
      Email: Email,
      Phone: Phone,
      //IsEmailVisible: IsEmailVisible,
      //IsPhoneVisible: IsPhoneVisible,
      SubscribedNotification: SubscribedNotification,
      IsRosterVisible: IsRosterVisible,
    };
    SaveRoster(dispatch, Roster, AuthToken);
  };

  const validateForm = (event) => {
    event.preventDefault();
    let valid = true;
    if (Name == "") {
      valid = false;
    }

    if (valid) handleSave();

    return valid;
  };
  //end redundant

  const renderRosterForm = () => {
    return (
      <div className="container">
        <form>
          <ul className="flex-outer">
            <li>
              <input
                type="checkbox"
                id="cbHideRoster"
                checked={IsRosterVisible}
                style={{ width: 25, height: 25 }}
                onClick={() => setIsRosterVisible(!IsRosterVisible)}
              />
              <label for="cbHideRoster">Only Visible to Admin</label>
            </li>
            <li>
              <label for="race">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter Name"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </li>
            <li>
              <label for="website">Website</label>
              <input
                type="text"
                id="website"
                placeholder="ex: www.mywebsite.com"
                value={Website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </li>
            <li>
              <label for="loftName">Email (hidden)</label>
              <input
                type="text"
                id="email"
                placeholder="Enter Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </li>
            {/* <li>
              <input
                type="checkbox"
                id="cbEmail"
                value={IsEmailVisible}
                defaultChecked={IsEmailVisible}
                onClick={(e) => setIsEmailVisible(!IsEmailVisible)}
                style={{ width: 25, height: 25 }}
              />
              <label for="cbEmail">Email Visible to Admin Only</label>
            </li> */}
            <li>
              <label for="phone">Phone (hidden)</label>
              <input
                type="text"
                id="phone"
                placeholder="Enter Phone"
                value={Phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </li>
            {/* <li>
              <input
                type="checkbox"
                id="cbPhone"
                value={IsPhoneVisible}
                defaultChecked={IsPhoneVisible}
                style={{ width: 25, height: 25 }}
                onClick={(e) => setIsPhoneVisible(!IsPhoneVisible)}
              />
              <label for="cbPhone">Phone Visible to Admin Only</label>
            </li> */}
            <li>
              <input
                type="checkbox"
                id="subscribedNotification"
                checked={SubscribedNotification}
                style={{ width: 25, height: 25 }}
                defaultChecked={SubscribedNotification}
                onClick={() =>
                  setSubscribedNotification(!SubscribedNotification)
                }
              />
              <label for="subscribedNotification">
                Check to receive Email Notification when an announcement or
                result is posted.
              </label>
            </li>
          </ul>
        </form>
      </div>
    );
  };

  return (
    <ModalWrapper
      title={props.title}
      handleOnClick={props.toDelete ? deleteConfirmed : validateForm}
      show={props.show}
      closeModal={props.closeModal}
    >
      {props.toDelete ? (
        <DeleteConfirmation
          Msg={"Are you sure you want to delete this member?"}
        />
      ) : (
        renderRosterForm(props.GUID)
      )}
    </ModalWrapper>
  );
};
export default AddEditRosterModal;
