import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  OpenFile,
  GetRace,
  SaveRace,
  SaveRaceFlyer,
  SaveRaceResult,
  ResetRace,
  EditRace,
  UpdateRace,
  PopulateRaceForm,
  DeleteRace,
} from "../../../actions";
import Upload from "../upload/Upload";
import TextBox from "../textboxes/TextBox";
import { StoreContext } from "../../../store";
import Button from "react-bootstrap/Button";
import "../../../../src/styles.less";
import ModalWrapper from "../modal/Modal";
import RaceForm from "../molecules/RaceForm";
import { useAccordionToggle } from "react-bootstrap";
import DeleteConfirmation from "../molecules/DeleteConfirmation";
import Form from 'react-bootstrap/Form';


const AddEditRaceModal = (props) => {
  const moment = require("moment");
  const { dispatch, Race, AuthToken } = useContext(StoreContext);

  //form fields
  let [raceDate, setRaceDate] = useState();
  let [Name, setName] = useState("");
  let [winner, setWinner] = useState("");
  let [Station, setStation] = useState("");
  let [ErrorMsg, setErrorMsg] = useState(false);
  let [FName, setFName] = useState("");
  let [FData, setFData] = useState("");
  const [fileName, setFileName] = useState("");
  const [displayFileName, setDisplayFileName] = useState("");
  const [flyerName, setFlyerName] = useState("");
  const [link, setLink]= useState("");
  const [displayFlyerName, setDisplayFlyerName] = useState("");
  const [fileData, setFileData] = useState("");
  const [fileFlyerData, setFileFlyerData] = useState("");

  const [raceName, setRaceName] = useState("");

  const [validForm, setValidForm] = useState(true);
  const [formData, setFormData] = useState(props.FormData);

  const [race, setRace] = useState(props.Race);


  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  useEffect(() => {
        
    if(!props.toDelete)
    {
      setRaceName(race.RaceName);
      setWinner(race.Winner);
      //setFileData(race.FileData);    
      //setFileFlyerData(race.FileFlyerData);
      setRaceDate(formatDate(race.RaceDate));  
      setDisplayFileName(race.FName);
      setDisplayFlyerName(race.FlyerName);
      setLink(race.Link);
    }
    
  }, []);

  const showErrorMessage = () => {
    if (!validForm && raceName.length == 0)
      return <div style={{ color: "red" }}>Race Name is Required.</div>;
  };


  let handleSave = () => {
    
    var currentTime = new Date()
    var year = currentTime.getFullYear()

    race.RaceName = raceName;
    race.Winner = winner;
    race.RaceDate = raceDate;
    race.Link = link;
    
    if(fileName !== null && fileName !== '')
    {
      race.FName = fileName;
      race.FileData = fileData;
    }

    
    if(flyerName !== null && flyerName !== '')
    {
      race.FlyerName = flyerName;
      race.FileFlyerData = fileFlyerData;
    }

    alert(race.IsCombine);
    if (race.GUID == "")
    {
      SaveRace(race, dispatch, AuthToken);
    }
    else
    {      
      EditRace(race, dispatch, AuthToken);
    }

    
  };

  const validateForm = (event) => {

    event.preventDefault();
    let valid = true;

    if (raceName.length == 0) {
      valid = false;
      setValidForm(false);
    }

    if (valid) handleSave();

    return valid;
  };

  
  
  const UploadResult = (sender) => {
    
    if(sender.target.files.length <= 0) return;

    let file = sender.target.files[0];
        
    setFileName(file.name);
    setFileData(file);

  };

  const ReadFileFlyer = (sender) => {
    
    if(sender.target.files.length <= 0) return;

    let file = sender.target.files[0];


    setFlyerName(file.name);
    setFileFlyerData(file);
    
  };


  const deleteConfirmed = (event) => {
    DeleteRace(formData, dispatch, AuthToken);
    props.closeModal();
  };

  const dt = new Date();

  const renderRaceForm = () => {
    

    return (
      <div className="edit-container">
        <div className="container">
          {/* hide this on edit */}
          {formData.GUID == "" ? (
            ""
          ) : (
            <span style={{ color: "red" }}>
              If you need to re-upload file, delete this race and just add a New
              Race
            </span>
          )}
        </div>
        <div className="container">
            <Form>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Race Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Race Name"   onChange={(e) => setRaceName(e.target.value)} value={raceName}/>
                </Form.Group>
               <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Winner</Form.Label>
                  <Form.Control type="text" placeholder="Enter 1st Place Winner"   onChange={(e) => setWinner(e.target.value)} value={winner}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Race Date</Form.Label>
                  <Form.Control type="date" onChange={(e) => setRaceDate(e.target.value)} value={raceDate}/>                  
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Link</Form.Label>
                  <Form.Control type="text" placeholder="Enter Link"   onChange={(e) => setLink(e.target.value)} value={link}/>
                </Form.Group>
                                  <Form.Group controlId="formFile" className="mb-3">
                                  {/* <Form.Label>{(fileName.length==0)? 'Select File' : <span>Selected File - {fileName}</span>}</Form.Label> */}
                                  {/* <Form.Control type="file"   onChange={(sender) => ReadFile(sender)} /> {(fileName=='')?'Select Race Result': fileName} */}

                                  <label htmlFor='fileUpload'>
                                    <a className='btn btn-primary'> Upload Race Result {fileName}</a>
                                </label>
                                  <input style={{display:'none'}} id='fileUpload' type='file'   onChange={(sender) => UploadResult(sender)} 
                                            accept='application/pdf, image/png'
                                    />
                                   {(displayFileName?.trim() !== '')?<span style={{fontStyle:'italic'}}> {displayFileName}</span>:''} 

                                  <br></br>
                                  {/* <Form.Control label="adsf" type="file"   onChange={(sender) => ReadFileFlyer(sender)} /> {(flyerName =='')?'Select Flyer': flyerName} */}
                                  <label htmlFor='fileUploadFlyer'>
                                    <a className='btn btn-primary'> Upload Flyer {flyerName}</a>
                                </label>
                                  <input style={{display:'none'}} id='fileUploadFlyer' type='file'   onChange={(sender) => ReadFileFlyer(sender)} 
                                            accept='application/pdf, image/png'
                                    />
                                   {(displayFlyerName?.trim() !== '')?<span style={{fontStyle:'italic'}}> {displayFlyerName}</span>:''} 
                                </Form.Group>
           

                {/* <Button variant="primary" onClick={handleSave}>
                  Save
                </Button>&nbsp;
                <Button variant="primary" onClick={ props.closeModal}>
                  Cancel
                </Button>  */}
              </Form>
           
        </div>
      </div>
    );
  };

  const handeClose = () => {};

  return (
    <ModalWrapper
      title={props.title}
      handleOnClick={props.toDelete ? deleteConfirmed : validateForm}
      show={props.show}   closeModal={props.closeModal}
    >
      {props.toDelete ? (
        <DeleteConfirmation
          Msg={`Are you sure you want to delete this race "${props.FormData.RaceName}"?`}
        />
      ) : (
        renderRaceForm(props.FormData.GUID)
      )}
    </ModalWrapper>
  );
};
export default AddEditRaceModal;
