import React, { useContext, useState, useEffect } from "react";
import schedule from "../images/raceschedule.jpg";



const Schedule = () =>
{



  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


    return (
      <div className="table-container">
         {/* <div style={homeContainer2}>
            <p>Race Schedule</p>
      </div> */}
        <img  style={{ maxWidth: '100%'}}src={schedule} alt="CJC Schedule"/>
      </div>
    );
  
}

export default Schedule;


const homeContainer2 = {
  fontSize: 30,
  color: "#343a40",
  textAlign: "center",
    fontFamily:"Fantasy"
}