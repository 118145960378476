import React, { createContext, useReducer } from "react";
import ActionTypes from "./actionTypes";

const RaceInitialState = {
  GUID: "",
  RaceName: "",
  Winner: "",
  ReleaseStation: "",
  RaceDate: "",
  FName: "",
  FlyerName:"",
  FileData: null,
  IsCombine:false,
  Link:""
};

const RosterInitialState = {
  AccountID: "",
  GUID: "",
  Name: "",
  LoftName: "",
  Email: "",
  Phone: "",
  IsEmailVisible: false,
  IsPhoneVisible: false,
  SubscribedNotofication: false,
  IsRosterVisible: false,
};
export const initialState = {
  Races:[],
  Posts: [],
  Race: RaceInitialState,
  ResetRace: RaceInitialState,
  Rosters: [],
  Roster: RosterInitialState,
  IsLogin: false,
  APIVersion: 0,
  Years: [(new Date()).getFullYear()],
  SelectedYear: "",
  AuthToken: {},
  Flyer:{}
};

function reducer(state, action) {
  switch (action.actionType) {
    case ActionTypes.LOAD_RACES:
      console.log('races' + action.payload)
      return {
        ...state,
       Races: action.payload,
      };
    case ActionTypes.RESET_RACE:
      return {
        ...state,
        Race: action.payload,
      };
    case ActionTypes.LOAD_POSTS:
      return {
        ...state,
        Posts: [...new Set(action.payload)],
      };
    case ActionTypes.LOAD_FLYER:
        return {
          ...state,
          Flyer: action.payload,
        };
    case ActionTypes.POPULATE_RACE_FORM:
      return {
        ...state,
        Race: action.payload,
      };
    case ActionTypes.UPDATE_RACE_OBJECT:
      return {
        ...state,
        Race: action.payload,
      };
    case ActionTypes.LOAD_ROSTERS:
      return {
        ...state,
        Rosters: action.payload,
      };
    case ActionTypes.LOAD_YEARS:
        return {
          ...state,
          Years: action.payload,
        };
    case ActionTypes.SAVE_ROSTER:
      return {
        ...state,
        Roster: action.payload,
      };
    // case ActionTypes.UPDATE_AUTH_TOKEN:
    //   return {
    //     ...state,
    //     AuthToken: action.payload,
    //   };
    case ActionTypes.GET_API_VERSION:
      return {
        ...state,
        APIVersion: action.payload,
      };
    case ActionTypes.GET_YEARS:
      return {
        ...state,
        Years: action.payload,
      };
    case ActionTypes.SET_SELECTED_YEAR:
      return {
        ...state,
        SelectedYear: action.payload,
      };

    case ActionTypes.UPDATE_AUTH_TOKEN:
      return {
        ...state,
        AuthToken: action.payload,
      };
    case ActionTypes.UPDATE_LOGIN:
      return {
        ...state,
        IsLogin: action.payload,
      };
  }
}

export const StoreContext = createContext({
  ...initialState,
  dispatch: undefined,
});

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ ...state, dispatch: dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
