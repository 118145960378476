import React, { useState, useContext, useEffect } from "react";
import { RequestResults, GetYears, SetSelectedYear } from "../actions";
import { StoreContext, initialState } from "../store";
import Table from "./atoms/tables/Table";
import Upload from "./atoms/upload/Upload";
import ToggleDisplay from "react-toggle-display";
import AddEditRaceModal from "./atoms/molecules/AddEditRaceModal";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import { relativeTimeRounding } from "moment";

let Races = (props) => {
  //const values = queryString.parse(window.location.search);
  const {
    Races,
    SelectedYear,
    Years,
    IsLogin,
    dispatch,
    RacesUpdated,
  } = useContext(StoreContext);

  let [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsAdmin(IsLogin);
  }, [IsLogin]);

  useEffect(() => {
    SetSelectedYear(dispatch, Years[0]);
  }, [Years[0]]);

  useEffect(() => {
    RequestResults(dispatch, SelectedYear);
  }, [SelectedYear]);

  const [reloadRaces, setReloadRace] = useState(true);
  const [showTop3, setShowTop3] = useState();
  const [visible, setVisible] = useState([]);

  const top3WinnersHandleClick = (evt, guid) => {
    if (evt.currentTarget == "svg") return;
    if (visible.includes(guid)) setVisible(visible.filter((i) => i != guid));
    else setVisible([...visible, guid]);
  };

  useEffect(() => {
    RequestResults(dispatch);
    GetYears(dispatch);
  }, []);

  let renderracesTR;
  const initialRaceData = {
    GUID: "",
    RaceName: "",
    Winner: "",
    ReleaseStation: "",
    RaceDate: new Date(),
    FName: "",
  };

  const [raceData, setRaceData] = useState(initialRaceData);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [deleteFlag, setDeleteFlag] = useState(false);

  const addEditClick = (evt, race) => {
    evt.stopPropagation();
    setRaceData(race);
    setShowModal(true);
    setModalTitle("Edit Race");
    setDeleteFlag(false);
  };
  const addNewClick = (race) => {
    setRaceData(race);
    setShowModal(true);
    setModalTitle("Add Race");
    setDeleteFlag(false);
  };

  const deleteRaceClick = (evt, race) => {
    evt.stopPropagation();

    setModalTitle("Please Confirm");
    setShowModal(true);
    setDeleteFlag(true);
    setRaceData(race);
  };

  const hideModalClick = () => {
    setShowModal(false);
  };

  if (Races.length == 0) {
    renderracesTR = (
      <tr>
        <td colSpan="5"> No Race Results Found </td>
      </tr>
    );
  } else {
    const sortedRaces = Races.sort(
      (a, b) => new Date(b.RaceDate) - new Date(a.RaceDate)
    );
    renderracesTR = sortedRaces.map((item, index) => {
      const dT = new Date(item.RaceDate);
      return (
        <React.Fragment>
          <tr
            onClick={(event) => top3WinnersHandleClick(event, item.GUID)}
            key={index}
          >
            {isAdmin ? (
              <td>
                <FontAwesomeIcon
                  icon={faEdit}
                  title="Edit Race"
                  onClick={(event) => addEditClick(event, item)}
                />
              </td>
            ) : (
              ""
            )}

            {isAdmin ? (
              <td>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  title="Delete Race"
                  onClick={(event) => deleteRaceClick(event, item)}
                />
              </td>
            ) : (
              ""
            )}

            <th scope="row">{index + 1}</th>

            <td>{`${
              dT.getMonth() + 1
            }/${dT.getDate()}/${dT.getFullYear()}`}</td>
            <td>{item.Winner}</td>
            <td>
              {item.RaceName} ({item.ReleaseStation})
            </td>
            <td>
              <a
                onClick={() => openFile(item.FName)}
                role="button"
                target="blank"
              >
                <svg
                  className="bi bi-display"
                  width="2em"
                  height="2em"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.75 15.5c.167-.333.25-.833.25-1.5h4c0 .667.083 1.167.25 1.5H13a.5.5 0 010 1H7a.5.5 0 010-1h.75z"></path>
                  <path
                    fill-rule="evenodd"
                    d="M15.991 5H4c-.325 0-.502.078-.602.145a.758.758 0 00-.254.302A1.46 1.46 0 003 6.01V12c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 00.538.143L4.01 13H16c.325 0 .502-.078.602-.145a.758.758 0 00.254-.302 1.464 1.464 0 00.143-.538L17 11.99V6c0-.325-.078-.502-.145-.602a.757.757 0 00-.302-.254A1.46 1.46 0 0015.99 5zM16 4H4C2 4 2 6 2 6v6c0 2 2 2 2 2h12c2 0 2-2 2-2V6c0-2-2-2-2-2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>{" "}
              </a>
            </td>
          </tr>
          {item.Top3.length < 1 ? (
            ""
          ) : (
            <tr
              className={visible.includes(item.GUID) ? "showTop3" : "hideTop3"}
            >
              <td colSpan="7" className="top-3">
                <h3>Top 3 Winners</h3>
                {displayTop3(item.Top3)}
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  }

  const renderAdminBtn = () => {
    return (
      <div className="row">
        <div className="col text-right">
          <i className="fas fa-plus"></i>
          <Button onClick={() => addNewClick(initialRaceData)}>
            <FontAwesomeIcon icon={faPlus} title="Add Race" />
            <span> Add Race</span>
          </Button>
          {showModal ? (
            <AddEditRaceModal
              title={modalTitle}
              show={showModal}
              closeModal={() => hideModalClick()}
              raceData={raceData}
              toDelete={deleteFlag}
            ></AddEditRaceModal>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const cols = ["#", "Date", "Winner", "Race", "Result"];
  return (
    <div className="table-container">
      {isAdmin ? renderAdminBtn() : ""}
      <div className="row mt-1">
        <div className="col-md-12  text-left">
          <DropdownButton id="dropdown-basic-button" title="Change Year">
            {Years.map((item) => (
              <Dropdown.Item
                href="#"
                onClick={() => SetSelectedYear(dispatch, item)}
              >
                {item}
              </Dropdown.Item>
            ))}
            <Dropdown.Item
              href="http://www.beta.lyndhurstpigeonclub.com/races.php"
              target="_blank"
            >
              View Archive
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col">
          <table className="table">
            <caption>
              {SelectedYear == undefined
                ? ""
                : `List of races for ${SelectedYear}`}
            </caption>
            <thead className="table-header">
              <tr>
                {isAdmin ? <th>edit</th> : ""}
                {isAdmin ? <th>delete</th> : ""}
                {cols.map((item) => (
                  <th scope="col">{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>{renderracesTR}</tbody>
          </table>
          <div className="row">
            <div className="col-xs-12"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Races;

const openFile = (fName) => {
  //window.open("../../files/races/" + fName, "_blank");
  const accountName = "cjccombine";

  window.open(
    `http://api.pigeonconnect.charmedcoder.com/UploadedFiles/${accountName}/raceresults/` + fName,
    "_blank"
  );
};

let fileReader;
let raceResult = {};

const displayTop3 = (winners) => {
  var newWinners = winners.split(",");

  return newWinners.map((winner) => <p>{winner}</p>);
};
