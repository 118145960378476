/* eslint-disable */

import React, { useState, useContext, useEffect } from "react";
import { format } from 'date-fns';
import "bootstrap/dist/css/bootstrap.min.css";
import { GetPosts,ConvertUTCDateToLocalDate } from "../actions";
import { StoreContext, initialState } from "../store";
import queryString from "query-string";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AddEditPostModal from "../components/atoms/molecules/AddEditPostModal";



const accountName = "cjccombine";



const openFile = (fName) => {
  if(fName.includes('http'))
  {
    window.open(
      fName,
      "_blank"
    );      
  }else{

    window.open(
      `http://api.pigeonconnect.charmedcoder.com/UploadedFiles/${accountName}/posts/` + fName,
      "_blank"
    );
  }
 
};

let Home = (props) => {
  const { Posts, IsLogin, dispatch } = useContext(StoreContext);
  //const values = queryString.parse(window.location.search);

  let [isAdmin, setIsAdmin] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [formData, setFormData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setIsAdmin(IsLogin);
  }, [IsLogin]);

  const initialPost = {
    GUID: "",
    Title: "",
    Detail: "",
    Attached: "",
    Link:""
  };

  useEffect(() => {
    GetPosts(dispatch);
  }, []);

  const addEditClick = (post) => {
    setFormData(post);
    setShowModal(true);
    setModalTitle("Edit Post");
    setDeleteFlag(false);
  };
  const addNewClick = (post) => {
    setFormData(post);

    setModalTitle("Post Announcement");
    setShowModal(true);

    setDeleteFlag(false);
  };

  const deletePostClick = (post) => {
    setFormData(post);
    setModalTitle("Please Confirm");
    setShowModal(true);
    setDeleteFlag(true);
    // setRaceData(race);
  };

  const hideModalClick = () => {
    setShowModal(false);
  };

  !function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');


  const renderEditDeleteBtn = (post) => {
    return (
      <div class="container text-right">
        <span className="m-2">
          <FontAwesomeIcon
            icon={faEdit}
            title="Edit Post"
            onClick={() => addEditClick(post)}
          />
        </span>
        <span onClick={() => deletePostClick(post)}>
          <FontAwesomeIcon icon={faTrashAlt} title="Delete Post" />
        </span>
      </div>
    );
  };

  const renderAdminBtn = () => {
    return (
      <div class="row mt-2" >
        <div class="col text-left">
          <i class="fas fa-plus"></i>
          <Button onClick={() => addNewClick(initialPost)}>
            <FontAwesomeIcon icon={faPlus} title="Post Announcement" />
            <span> Post Announcement</span>
          </Button>
          {showModal ? (
            <AddEditPostModal
              show={showModal}
              closeModal={() => hideModalClick()}
              FormData={formData}
              title={modalTitle}
              toDelete={deleteFlag}
            ></AddEditPostModal>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
  let render;
  if (Posts.length <= 0) {
    render = (
      <div className="jumbotron jumbotron-fluid">
        <div className="container">          
          <p className="lead">
            There's no announcement at the moment, please check back later.
          </p>
        </div>
      </div>
    );
  } else {
    render = Posts.map((item) => {
      if (item.Attached.length > 3 || item?.Link?.length > 5) {
        return (
          <div class="jumbotron jumbotron-fluid">
            {isAdmin ? renderEditDeleteBtn(item) : ""}

            <div className="container">
            <p><span style={{fontSize:'20px', fontWeight:'bold'}}>{item.Title}</span><br></br><span style={{ fontSize:'13px'}}>Updated {format(ConvertUTCDateToLocalDate(new Date(item.CreatedDate)), 'MM/dd/yyyy hh:mm a')}</span></p>
              <p className="fontPrimary">{item.Detail}</p>
              <p className="lead">
                <a
                  className="btn btn-primary btn-md"
                  // href="src/files/Auction_ad_page_2.pdf"
                  onClick={() => openFile((item?.Link?.length>5) ? item.Link : item.Attached)}
                  role="button"
                  target="blank"
                >
                  <span style={{fontFamily:'Helvetica'}}>Learn more</span>
                  
                </a>
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div class="jumbotron jumbotron-fluid">
            {isAdmin ? renderEditDeleteBtn(item) : ""}
            <div class="container">
              <p><span style={{fontSize:'20px', fontWeight:'bold'}}>{item.Title}</span><br></br><span style={{ fontSize:'13px'}}>Updated {format(ConvertUTCDateToLocalDate(new Date(item.CreatedDate)), 'MM/dd/yyyy hh:mm a')}</span></p>
              <p class="fontPrimary">{item.Detail}</p>
            </div>
          </div>
        );
      }
    });
  }


  
  return (
    <div>
      <div> {isAdmin ? renderAdminBtn() : ""}</div>
         <div style={homeContainer}>         
      </div>
            
      {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScRCg05Jl5lKXLONNefxTMRvMTu7ixRb_05rdlHsJ0mhEohRA/viewform?embedded=true" width="100%" height="500px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
      
      {render}
      </div>
  );
};

export default Home;


const homeContainer = {
  fontSize: 30,
  color: "#343a40",
  textAlign: "center",
    fontFamily:"Helvetica",
    letterSpacing:'1px'
}

const homeContainer2 = {
  fontSize: 30,
  color: "#343a40",
  textAlign: "center",
    fontFamily:"Fantasy"
}
const postContainer = {
  fontSize: 20,
  color: "#343a40",
  textAlign: "left",
    fontFamily:"Helvitica"
}
const postTitle ={
  fontSize:'20px',
  fontWeight:'bold',
  fontFamily:"Helvetica"
};
const postDetail ={};

const liberationInfo ={
  
  border: '0px green solid',
  padding:'20px',
  
  
};

const liberationInfoSpan = {
  backgroundColor:'#36ace4'
};